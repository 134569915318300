import axios from 'axios';
import { domain } from './domain';

export const login = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data,
    };
    return axios(domain + '/user/login', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const auth = (token) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + token 
        },
    };
    return axios(domain + '/user/token', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const getUsers = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + token 
        },
        data
    };
    return axios(domain + '/user/get', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const checkUser = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + token 
        },
        data
    };
    return axios(domain + '/user/check', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const updateUser = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + token 
        },
        data
    };
    return axios(domain + '/user', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const createUser = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            "Authorization": 'Bearer ' + token 
        },
        data
    };
    return axios(domain + '/user', requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};